<template>
    <b-row>
      <b-col cols="12">
  
          <!-- Stats Card w/ Area Chart -->
      <b-row>
        
          <b-col lg="3" sm="6">
            <statistic-card-with-area-chart
              v-if="sum_stats.bids"
              icon="WindIcon"
              :statistic="kFormatter(Number(sum_stats.bids))"
              statistic-title="Bids"
              :chart-data="graph_stats.bids"
            />
          </b-col>
          <b-col lg="3" sm="6">
          <statistic-card-with-area-chart
            v-if="sum_stats.wins"
            icon="LayersIcon"
            color="success"
            :statistic="kFormatter(Number(sum_stats.wins))"
            statistic-title="Impressions"
            :chart-data="graph_stats.wins"
          />
        </b-col>
        <b-col lg="3" sm="6">
          <statistic-card-with-area-chart
            v-if="sum_stats.clicks"
            icon="PackageIcon"
            color="danger"
            :statistic="kFormatter(Number(sum_stats.clicks))"
            statistic-title="Clicks"
            :chart-data="graph_stats.clicks"
          />
        </b-col>
        <b-col lg="3" sm="6">
          <statistic-card-with-area-chart
            v-if="sum_stats.spend"
            icon="CreditCardIcon"
            color="warning"
            :statistic="kFormatter(Number(sum_stats.spend))"
            statistic-title="Spend"
            :chart-data="graph_stats.spend"
          />
        </b-col>
        
  
        
      </b-row>
  
      <!-- Stats Card Horizontal -->
      <b-row>
        <b-col
          lg="3"
          sm="6"
        >
          <statistic-card-horizontal
            icon="FolderPlusIcon"
            :statistic="1"
            statistic-title="Active Campaigns"
          />
        </b-col>
        <b-col
          lg="3"
          sm="6"
        >
          <statistic-card-horizontal
            icon="FolderPlusIcon"
            color="success"
            :statistic="Number(sum_stats.winrate).toFixed(2)"
            statistic-title="Win Rate"
          />
        </b-col>
        <b-col
          lg="3"
          sm="6"
        >
          <statistic-card-horizontal
            icon="FolderPlusIcon"
            color="danger"
            :statistic="Number(sum_stats.ctr).toFixed(2)"
            statistic-title="CTR"
          />
        </b-col>
        <b-col lg="3" sm="6">
          <statistic-card-horizontal
            icon="FolderPlusIcon"
            color="warning"
            :statistic="Number(sum_stats.ecpm)"
            statistic-title="eCPM"
          />
        </b-col>
      </b-row>
  
  
        <b-card-code title="Campaign Information">
          <!-- search input -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
          <!-- table -->
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
  
              <!-- Column: Inventory Status -->
              <span v-if="props.column.field === 'win_rate'" class="progress" style="height:8px">
                  <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="50" class="progress-bar" style="width:50%;"></div>
              </span>
  
              <!-- Column: Visibility Rate -->
              <span v-if="props.column.field === 'visibility_rate'">
                {{ (props.row.wins > 0) ? Number((props.row.visible/props.row.wins) * 100).toFixed(2) : 0 }}%
              </span>
  
              <!-- Column: CTR Rate -->
              <span v-if="props.column.field === 'click_ctr'">
                {{ (props.row.wins > 0) ? ((props.row.clicks/props.row.wins) * 100).toFixed(2) : 0 }}%
              </span>
  
              <!-- Column: Click CPC -->
              <span v-if="props.column.field === 'click_cpc'">
                {{ (props.row.clicks > 0) ? (props.row.spend/props.row.clicks).toFixed(2) : 0 }}
              </span>
              
              <!-- Column: eCPM -->
              <span v-if="props.column.field === 'cpm_ecpm'">
                {{ (props.row.wins > 0) ? ( (props.row.spend/props.row.wins) * 1000 ).toFixed(2) : 0.00 }}
              </span>
  
              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item>
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
  
              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
  
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
  
        </b-card-code>
      </b-col>
    </b-row>
  </template>
  
  <script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { BCard, BRow, BCol,BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BFormCheckbox} from 'bootstrap-vue'
  import { VueGoodTable } from 'vue-good-table'
  import store from '@/store/index'
  import useJwt from '@/auth/jwt/useJwt'
  
  // chart
  import { kFormatter } from '@core/utils/filter'
  import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
  import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
  
  
  
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BCardCode,
      VueGoodTable,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BDropdown,
      BDropdownItem,
      BFormCheckbox,
      // chart
      StatisticCardWithAreaChart,
      StatisticCardHorizontal
    },
    data() {
      return {
        pageLength: 3,
        dir: false,
        columns: [
          {
            label: 'Name',
            field: 'campaign_name',
          },
          {
            label: 'BIDS',
            field: 'bids',
          },
          {
            label: 'IMPRESSION',
            field: 'wins',
          },
          {
            label: 'WIN RATE',
            field: 'win_rate',
          },
          {
            label: 'PIXELS',
            field: 'pixels',
          },
          {
            label: 'VISIBLE',
            field: 'visible',
          },
          {
            label: 'VISIBILITY RATE',
            field: 'visibility_rate',
          },
          {
            label: 'CLICKS',
            field: 'clicks',
          },
          {
            label: 'CTR',
            field: 'click_ctr',
          },
          {
            label: 'CPC',
            field: 'click_cpc',
          },
          {
            label: 'SPEND',
            field: 'spend',
          },
          {
            label: 'eCPM',
            field: 'cpm_ecpm',
          },
          {
            label: 'Action',
            field: 'action',
          }
        ],
        rows: [],
        searchTerm: '',
        // Area charts
        graph_stats: {bids:[{}],wins:[{}],clicks:[{}],spend:[{}]},
        sum_stats:{}
      }
    },
    computed: {    
      direction() {
        if (store.state.appConfig.isRTL) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.dir = true
          return this.dir
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = false
        return this.dir
      },
    },
    created() {
      const accessToken = useJwt.getToken('accessToken')
      this.$http.get('https://api.purplepatch.online/advertiser', {
        headers: {
          Authorization:'Bearer ' + accessToken,
          'x-access-token': accessToken 
        }
      })
      .then(res => { 
        var data = res.data;
        this.rows = data.active_campaigns_list;
        this.sum_stats=data.sum_stats;
        this.graph_stats.bids[0].data = data.graph_stats.bids;
        this.graph_stats.wins[0].data = data.graph_stats.wins;
        this.graph_stats.clicks[0].data = data.graph_stats.clicks;
        this.graph_stats.spend[0].data = data.graph_stats.spend;
      })
  
    },
    methods:{
      kFormatter
    }
  
  }
  </script>
  
  <style lang="scss" >
    @import '@core/scss/vue/libs/publisher.scss';
  </style>  